import { isOffline, OFFLINE_FILE_BUCKET } from 'helpers/constants';
import { fixSlash } from 'utils/querystring';

const HTTPS_PREFIX = 'https:';

function fixProtocol(url = '') {
	if (window.location.protocol === HTTPS_PREFIX) {
		return url.trim().replace(/^http:/i, HTTPS_PREFIX);
	} else {
		return url;
	}
}

const S3_OFFLINE_URL = `http://localhost:4569/${OFFLINE_FILE_BUCKET}`;
const FS_OFFLINE_REGEX = new RegExp(`^https://${OFFLINE_FILE_BUCKET}(.+)`);

export const fixContentUrlForOffline = isOffline
	? (url) => {
			if (url) {
				const match = FS_OFFLINE_REGEX.exec(url);
				if (match) {
					return S3_OFFLINE_URL + match[1];
				}
			}
			return url;
	  }
	: (url) => url;

// Returns string for text/markdown, 'any' for parsed JSON.
export function getFile({ url, type = 'text' }: { url: string; type?: string }): Promise<string | any> {
	return fetch(fixProtocol(fixSlash(fixContentUrlForOffline(url)))).then((response) => {
		if (!response.ok) {
			throw new Error(response.statusText + ' (' + response.status + ')');
		}
		switch (type) {
			case 'json':
				return response.json();
			case 'text':
			case 'markdown':
			default:
				return response.text();
		}
	});
}

export type PutFileProgressHandler = (current: number, total: number, done: boolean) => void;

export function putFile({ url, file, onProgress }: { url: string; file: File; onProgress?: PutFileProgressHandler }): {
	abort: () => void;
	promise: Promise<boolean>;
} {
	const xhr = new XMLHttpRequest();
	let aborted = false;
	function abort() {
		aborted = true;
		xhr.abort();
	}
	// Promise resolves to true on success, false on abort.
	const promise = new Promise<boolean>((resolve, reject) => {
		const size = file.size;
		let current = 0;
		xhr.upload.addEventListener('loadstart', () => {
			onProgress?.(0, file.size, false);
		});
		xhr.upload.addEventListener('progress', (event) => {
			// upload progress events get length info
			if (event.lengthComputable) {
				current = event.loaded;
				// Report not done here so that all done signaling is in a single place.
				onProgress?.(event.loaded, event.total, false);
			}
		});
		xhr.addEventListener('loadend', () => {
			// xhr loadend gets valid status; upload loadend gets a 0 for some reason
			onProgress?.(current, size, true);
			if (!aborted && xhr.status !== 200) {
				const error = new Error(`Upload failed with status ${xhr.status}.`);
				reject(error);
			} else {
				resolve(!aborted);
			}
		});
		xhr.open('PUT', url);
		xhr.setRequestHeader('Content-Type', file.type);
		xhr.send(file);
	});
	return { abort, promise };
}
